import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import Dropdown from '../utils/Dropdown';

function Header(props) {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [displayBanner, setDisplayBanner] = useState(true);
  const [overrideDarkTheme, setOverrideDarkTheme] = useState(props.overrideDarkTheme ? props.overrideDarkTheme : false);

  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [top, setTop] = useState(true);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  const updateBanner = (value) => {
    if (value == false) {
      document.documentElement.classList.remove('banner');
    }
    setDisplayBanner(value)
  
  }

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    // document.documentElement.classList.add('dark')

    if (displayBanner) {
      document.documentElement.classList.add('banner')

    }

    let classNames = document.documentElement.classList;

    console.log("classes ", classNames);
    if (classNames.contains("dark")) {
      setIsDarkTheme(true);
    }
    else {
      setIsDarkTheme(false);
    }


    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
      !top && `${isDarkTheme && !overrideDarkTheme ? "bg-gray-900" : "bg-white"} shadow-lg` 
    }`}>
        <div className={displayBanner ? "bg-indigo-600" : "hidden"}>
        <div className="max-w-8xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-purple-600">
                <svg
                  class="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  width="40"
                  height="40"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  />
                </svg>
              </span>
              <p className="ml-3 font-small text-white truncate">
                <span className="md:hidden">
                Get 20% off with our latest discount. For a limited time only!
                </span>
                <span class="hidden md:inline">
                Get 20% off with our early-bird discount. For a limited time only!
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                to="/pricing"
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-small text-indigo-600 bg-white hover:bg-indigo-50"
              >
                Get Started
              </Link>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                onClick={() => {
                  updateBanner(false);
                }}
                className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <span className="sr-only">Dismiss</span>

                <svg
                  class="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                  width="30"
                  height="40"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className={`w-full`}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">

          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Lightbox.js">
              <svg className="w-8 h-8 fill-current text-purple-600" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.952 14.751a260.51 260.51 0 00-4.359-4.407C23.932 6.734 20.16 3.182 16.171 0c1.634.017 3.21.28 4.692.751 3.487 3.114 6.846 6.398 10.163 9.737.493 1.346.811 2.776.926 4.262zm-1.388 7.883c-2.496-2.597-5.051-5.12-7.737-7.471-3.706-3.246-10.693-9.81-15.736-7.418-4.552 2.158-4.717 10.543-4.96 16.238A15.926 15.926 0 010 16C0 9.799 3.528 4.421 8.686 1.766c1.82.593 3.593 1.675 5.038 2.587 6.569 4.14 12.29 9.71 17.792 15.57-.237.94-.557 1.846-.952 2.711zm-4.505 5.81a56.161 56.161 0 00-1.007-.823c-2.574-2.054-6.087-4.805-9.394-4.044-3.022.695-4.264 4.267-4.97 7.52a15.945 15.945 0 01-3.665-1.85c.366-3.242.89-6.675 2.405-9.364 2.315-4.107 6.287-3.072 9.613-1.132 3.36 1.96 6.417 4.572 9.313 7.417a16.097 16.097 0 01-2.295 2.275z" />
              </svg>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:visible md:flex md:grow">

            {/* Desktop menu links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <Link to="/#features" className={`nav_link ${overrideDarkTheme ? "text-gray-500" : "text-gray-500 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200"}  px-4 py-2 flex items-center transition duration-150 ease-in-out`}>
                  Features
                </Link>
              </li>
              <li>
                <Link to="/pricing/"  
                className={`nav_link ${overrideDarkTheme ? "text-gray-500" : "text-gray-500 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200"}  px-4 py-2 flex items-center transition duration-150 ease-in-out`}>
                  Pricing</Link>
              </li>
              <li>
                <Link to="/docs/"  className={`nav_link ${overrideDarkTheme ? "text-gray-500" : "text-gray-500 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200"}  
                px-4 py-2 flex items-center transition duration-150 ease-in-out`}>Docs</Link>
              </li>
              <li>
                <Link to="/demo/"  className={`nav_link ${overrideDarkTheme ? "text-gray-500" : "text-gray-500 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200"}  
                px-4 py-2 flex items-center transition duration-150 ease-in-out`}>Demos</Link>
              </li>
              <li>
                <Link to="/contact/" className={`nav_link ${overrideDarkTheme ? "text-gray-500" : "text-gray-500 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200"}  px-4 py-2 flex items-center transition duration-150 
                ease-in-out`}>Contact</Link>
              </li>
              {/* 1st level: hover */}
              {/* <Dropdown title="Support"> */}
                {/* 2nd level: hover */}
                {/* <li>
                  <Link to="/contact" className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight">Contact us</Link>
                </li> */}
                {/* <li>
                  <Link to="/help" className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight">Help center</Link>
                </li> */}
                {/* <li>
                  <Link to="/404" className="font-medium text-sm text-gray-400 hover:text-purple-600 flex py-2 px-4 leading-tight">404</Link>
                </li> */}
              {/* </Dropdown> */}
            </ul>

            {/* Desktop sign in links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              {/* <li>
                <Link to="/pricing" className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out">Purchase</Link>
              </li> */}
              <li>
                <Link to="/pricing" className="btn-sm px-4 py-2 font-medium inline-flex items-center 
                justify-center border border-transparent rounded-sm leading-snug transition duration-150 ease-in-out 
                text-white bg-purple-600 hover:bg-purple-700 ml-3">Purchase</Link>
              </li>
            </ul>

          </nav>

          {/* Mobile menu */}
          <div className="visible md:hidden">

            {/* Hamburger button */}
            <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav id="mobile-nav" ref={mobileNav} className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out" style={mobileNavOpen ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: .8 } }>
              <ul className="bg-gray-800 px-4 py-2">
                <li>
                  <Link to="/#features" className="flex text-gray-300 hover:text-gray-200 py-2">Features</Link>
                </li>
                <li>
                  <Link to="/pricing/" className="flex text-gray-300 hover:text-gray-200 py-2">Pricing</Link>
                </li>
                <li>
                  <Link to="/docs/" className="flex text-gray-300 hover:text-gray-200 py-2">Docs</Link>
                </li>
                <li>
                  <Link to="/demo/" className="flex text-gray-300 hover:text-gray-200 py-2">Demos</Link>
                </li>
                <li>
                  <Link to="/contact/" className="flex text-gray-300 hover:text-gray-200 py-2">Contact</Link>
                </li>
                {/* <li className="py-2 my-2 border-t border-b border-gray-700">
                  <span className="flex text-gray-300 py-2">Contact</span>
                  <ul className="pl-4">
                    <li>
                      <Link to="/contact" className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/help" className="text-sm flex font-medium text-gray-400 hover:text-gray-200 py-2">Help center</Link>
                    </li> 

                  </ul>
                </li> */}
                {/* <li>
                  <Link to="/signin" className="flex font-medium w-full text-purple-600 hover:text-gray-200 py-2 justify-center">Sign in</Link>
                </li> */}
                <li>
                  <Link to="/pricing" className="font-medium w-full inline-flex items-center justify-center 
                  border border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 
                  ease-in-out">Purchase</Link>
                </li>
              </ul>
            </nav>

          </div>

        </div>
      </div>
      </div>
      
    </header>
  );
}

export default Header;
