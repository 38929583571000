import * as React from "react";

import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Sticky from "sticky-js";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";

import AOS from 'aos';
import 'aos/dist/aos.css';

const TemplateWrapper = ({ children }) => {

  useEffect(() => {
    // document.documentElement.classList.add('dark')

    if (localStorage.theme === 'dark' || (window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      // document.documentElement.classList.add('dark')
    }
    // else {
    //   document.documentElement.classList.remove('dark')
    // }

    AOS.init({
      once: false,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
    AOS.refresh();
    const sticky = new Sticky('[data-sticky]');

  });

  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`https://source.unsplash.com/pAKCx4y2H6Q/1400x1200`}
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-656722412"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-656722412');
          `}
        </script>
        <script>{`!function(o,i){window.provesrc&&window.console&&console.error&&console.error("ProveSource is included twice in this page."),provesrc=window.provesrc={dq:[],display:function(){this.dq.push(arguments)}},o._provesrcAsyncInit=function(){provesrc.init({apiKey:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiI2M2EwNDc3NDJjMGFlNzBiM2FjYTFkODIiLCJpYXQiOjE2NzE0NDg0MzZ9.RvQ28PFDl96xb10HJZ1wFWP87d2FJkn6hg5kpAxbsQs",v:"0.0.4"})};var r=i.createElement("script");r.type="text/javascript",r.async=!0,r["ch"+"ar"+"set"]="UTF-8",r.src="https://cdn.provesrc.com/provesrc.js";var e=i.getElementsByTagName("script")[0];e.parentNode.insertBefore(r,e)}(window,document);`}</script>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap" rel="stylesheet" /> 
      </Helmet>
      {/* <Header /> */}
      <div className="bg_white">{children}</div>

    </div>
  );
};

export default TemplateWrapper;
